import React from 'react'
import '../css/contact.css'
import {ContentConsumer} from '../ContentContext';

class Contact extends React.Component {
    render() {
        return (
            <ContentConsumer>
            {
                props => {
                    const { contact } = props;
                    return (
                        <section id="contact" className="contact">
                            <div className="contact__container container">
                                <div className="contact__content">
                                    <h2 className="contact__title">{contact?.contactTitle}</h2>
                                    <div className="contact__info">
                                        <div className="contact__left">
                                            <a href={`mailto:${contact?.contactEmail}`} className="contact__link link">{contact?.contactEmail}</a>
                                            <span className="contact__text">sau</span>
                                            <a href={`tel:${contact?.contactNumber}`} className="contact__link link">{contact?.contactNumber}</a>
                                        </div>
                                        <hr className="vertical-line"/>
                                        <div className="contact__right">
                                            <a href={contact?.contactFacebookUrl} target="_blank" rel="noreferrer" className="link__social  contact__link link"><span className="icon--facebook"></span>{contact?.contactFacebook}</a>
                                            <a href={contact?.contactInstaUrl} target="_blank" rel="noreferrer" className="link__social  contact__link link"><span className="icon--instagram"></span>{contact?.contactInsta}</a>
                                            <p className="contact__text">{contact?.contactAdress}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    )
                }
            }
            </ContentConsumer>
        )
    }
}

export default Contact