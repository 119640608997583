import React, { Component } from "react"
import Slider from "react-slick"
import '../css/slick.css'
import {ContentConsumer} from '../ContentContext';

export default class HouseSlider extends Component {
    constructor(props) {
        super(props);
        this.state = {
            nav1: null,
            nav2: null
        };
    }

    componentDidMount() {
        this.setState({
          nav1: this.slider1,
          nav2: this.slider2
        });
    }

    render() {
        const settings = {
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
        };
        return (
            <ContentConsumer>
                    {
                        props => {
                            const {houseslider} = props;
                            return(
                                <div className="slider-house">
                                    <Slider {...settings}
                                    className="slick-upper"
                                    asNavFor={this.state.nav2}
                                    ref={slider => (this.slider1 = slider)}>
                                        { houseslider && houseslider?.map((item, index) => {
                                            return(
                                                <div key={index}>
                                                    <div className="slick__card">
                                                        <img className="slick__card-image" src={item?.sliderImage.asset.url} alt="Imagine carusel"/>
                                                    </div>
                                                </div>
                                            )
                                        }
                                        )}
                                    </Slider>
                                    <Slider
                                    asNavFor={this.state.nav1}
                                    ref={slider => (this.slider2 = slider)}
                                    slidesToShow={3}
                                    swipeToSlide={true}
                                    focusOnSelect={true}
                                    dots={true}
                                    arrows={false}
                                    className="slick-thumbnails"
                                    >
                                        { houseslider && houseslider?.map((item, index) => {
                                            return(
                                                <div key={index}>
                                                    <div className="slick__card">
                                                        <img className="slick__card-image" src={item?.sliderImage.asset.url} alt="Imagine carusel"/>
                                                    </div>
                                                </div>
                                            )
                                        }
                                        )}
                                    </Slider>
                                </div>
                            )
                        }
                    }
            </ContentConsumer>
        );
    }
}