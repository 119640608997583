import React from 'react'

class Options extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            list: this.props.data
        }
    }

    render() {
        return (
            <>
                <section className="options">
                    <div className="technical__info">
                        <div className="container">
                            <h2 className="technical__sub-title">Opționale pentru acest proiect</h2>
                            <ul className="technical__list">
                                {
                                    this.state.list?.map((item,index) => {
                                        return(
                                            <li key={index} className="technical__item">{item}</li>
                                        )
                                    })
                                }
                            </ul>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}

export default Options