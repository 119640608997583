import React from 'react'
import { NavLink } from "react-router-dom"
import '../css/about.css'

class About extends React.Component {
    constructor(props) {
        super(props);
        this.state = { data: this.props.data };
    }

    render() {
        const data = this.state.data;

        return (
            <>
                <section className="about">
                    <div className="about__container container">
                        <div className="about__content">
                            <h2 className="about__title">{data.aboutTitle}</h2>
                            <p className="about__description">
                                {data.aboutDescription}
                            </p>
                            <NavLink to="/despre" className="button button--about">{data.btnAbout}</NavLink>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}

export default About