import React from 'react'
import '../css/technical.css'
import {ContentConsumer} from '../ContentContext'
import client from '../client.js'
import imageUrlBuilder from '@sanity/image-url'

const builder = imageUrlBuilder(client)

function urlFor(source) {
  return builder.image(source)
}

class Technical extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            list: this.props.data
        }
    }

    render() {
        return (
            <ContentConsumer>
                {
                    props => {
                        const {technical} = props;
                        if(technical != null)
                        return (
                            <>
                            <section className="technical">
                                <div className="technical__container container">
                                    <div className="technical__content">
                                        <h2 className="technical__title">{technical[0].technicalTitle}</h2>
                                        <table className='table'>
                                            <tbody>
                                                {
                                                    technical[0].technicalData && technical[0].technicalData?.map((item, index) => {
                                                        return (
                                                            <tr key={index} className="table__row">
                                                                <th className="table__column">{item.title}</th>
                                                                <th className="table__column"><span className="table__number">{item.value}</span>
                                                                    <span style={{display: item.metrics? 'inline': 'none'}}>m
                                                                    <sup style={{display: item.metricsSecond? 'inline': 'none'}}>2</sup>
                                                                    </span>
                                                                </th>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                        {
                                            technical[0].technicalDraws && technical[0].technicalDraws?.map((item, index) => {
                                                return (
                                                    <div key={index} className="technical__box">
                                                        <img className="technical__image" src={urlFor(item.heroImage).url()} alt="Schema"/>
                                                        <div className="technical__box-content">
                                                            <p className="technical__sub-title">{item?.title}</p>
                                                            <p className="technical__description">{item?.value}</p>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                                <div className="technical__info">
                                    <div className="container">
                                        <h2 className="technical__sub-title">Pachete De Proiectare și Construcție - Bugetul Necesar</h2>
                                        <ul className="technical__list">
                                            {
                                                this.state.list?.map((item,index) => {
                                                    return(
                                                        <li key={index} className="technical__item">{item}</li>
                                                    )
                                                })
                                            }
                                        </ul>
                                    </div>
                                </div>
                            </section>
                            </>
                        )
                    }
                }
            </ContentConsumer>
        )
    }
}

export default Technical