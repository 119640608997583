import React from 'react'
import Header from '../components/header'
import Contact from '../components/contact'
import Footer from '../components/footer'
import {ContentConsumer} from '../ContentContext';
import '../css/details.css'
import client from '../client.js'
import { ReactComponent as Logo } from '../images/logo.svg';
import {useParams} from "react-router-dom";
import { Navigate } from "react-router-dom";
import imageUrlBuilder from '@sanity/image-url'

const builder = imageUrlBuilder(client)

function urlFor(source) {
  return builder.image(source)
}

class Details extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            indexVal: props.params.id
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        let indexVal = this.state.indexVal;

        return (
            <ContentConsumer>
                {
                    props => {
                        const {homeslider} = props;
                        if(homeslider !== null)
                        {
                            let data = homeslider[indexVal];
                            if(data === undefined) {
                                return <Navigate to="/error" replace={true} />
                            } 
                            else
                                return (
                                    <>
                                        <Header/>
                                        <section style={ { backgroundImage: `url(${data?.ofertsImage.asset.url})`} } className="hero hero--details">
                                            <div className="overlay"></div>
                                            <div className="hero__content container">
                                                <h1 className="hero__title hero__title--details">{data?.ofertsSubTitle} {data?.ofertsType}</h1>
                                            </div>
                                        </section>
                                        <section className="details">
                                            <div className="container">
                                                <h2 className="details__title">{data?.detailsTitle}</h2>
                                                {
                                                    data && data.detailsData?.map((item, index) => {
                                                        return(
                                                            <div key={index} className="details__box">
                                                                <div className="details__right">
                                                                    <p className="details__subtitle">{item?.title}</p>
                                                                    <p className="details__desccription">
                                                                        {item?.description}
                                                                    </p>
                                                                    <ul className="details__list">
                                                                        {
                                                                            item?.list && item?.list.map((element, id) => {
                                                                                return(
                                                                                    <li key={id} className="details__element">{element}</li>
                                                                                )
                                                                            })
                                                                        }
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </section>
                                        <hr className="horizontal-line"/>
                                        <Contact/>
                                        <Footer/>
                                    </>
                                )
                        } else {
                            return (
                                <>
                                <section className="loading">
                                    <div className="container container--loading">
                                        <div className="ring">
                                            <div className="ring__logo">
                                                <Logo/>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                                </>
                            )
                        }
                    }
                }
            </ContentConsumer>
        )
    }
}

const withRouter = WrappedComponent => props => {
    const params = useParams();
  
    return (
      <WrappedComponent
        {...props}
        params={params}
      />
    );
};

export default withRouter(Details)