import React from 'react'
import Header from '../components/header'
import Footer from '../components/footer'
import '../css/privacy.css'

class Privacy extends React.Component {
    render() {
        return (
            <>
                <Header/>
                <section className="privacy">
                    <div className="container">
                        <h1 className="privacy__title">Politică de confidențialitate</h1>

<p>La imobiliarelaurentiu, accesibil de pe www.imobiliarelaurentiu.ro, una dintre principalele noastre prioritati este intimitatea vizitatorilor nostri. Acest document de Politică de confidențialitate conține tipuri de informații care sunt colectate și înregistrate de imobiliarelaurentiu și modul în care le folosim.</p>

<p>Dacă aveți întrebări suplimentare sau aveți nevoie de mai multe informații despre Politica noastră de confidențialitate, nu ezitați să ne contactați.</p>

<p>Această Politică de confidențialitate se aplică numai activităților noastre online și este valabilă pentru vizitatorii site-ului nostru în ceea ce privește informațiile pe care le-au partajat și/sau le colectează în imobiliarelaurentiu. Această politică nu se aplică niciunei informații colectate offline sau prin alte canale decât acest site web.</p>

<h2>Consimţământ</h2>

<p>Prin utilizarea site-ului nostru web, sunteți de acord cu Politica noastră de confidențialitate și sunteți de acord cu termenii acesteia.</p>

<h2>Informațiile pe care le colectăm</h2>

<p>Informațiile personale care vi se cer să le furnizați și motivele pentru care vi se cere să le furnizați vă vor fi clarificate în momentul în care vă cerem să furnizați informațiile dumneavoastră personale.</p>
<p>Dacă ne contactați direct, este posibil să primim informații suplimentare despre dvs., cum ar fi numele dvs., adresa de e-mail, numărul de telefon, conținutul mesajului și/sau atașamentele pe care ni le puteți trimite și orice alte informații pe care ați putea alege să le furnizați</p>

<h2>Cum folosim informațiile?</h2>

<p>Folosim informațiile pe care le colectăm în diferite moduri, inclusiv pentru:</p>

<ul>
<li>Furnizarea, operarea și întreținerea site-ul nostru web</li>
<li>Îmbunătățirea, personalizarea și extinderea site-ul nostru web</li>
<li>Înțelegerea și analizrea modului în care ne folosiți website-ul</li>
<li>Dezvoltarea noilor produse, servicii, caracteristici și funcționalități</li>
<li>Comunicarea cu dvs., fie direct, fie prin intermediul unuia dintre partenerii noștri, inclusiv pentru serviciul clienți, pentru a vă oferi actualizări și alte informații referitoare la site-ul web și în scopuri de marketing și promoționale</li>
<li>Vă trimitem email-uri</li>
<li>Căutând și prevenind frauda</li>
</ul>

<h2>Fișiere jurnal</h2>

<p>imobiliarelaurentiu urmează o procedură standard de utilizare a fișierelor jurnal. Aceste fișiere înregistrează vizitatorii atunci când vizitează site-uri web. Toate companiile de găzduire fac acest lucru și o parte din analiza serviciilor de găzduire. Informațiile colectate de fișierele jurnal includ adrese de protocol de internet (IP), tipul de browser, furnizorul de servicii de internet (ISP), marcajul de dată și oră, pagini de referință/ieșire și, eventual, numărul de clicuri. Acestea nu sunt legate de nicio informație care poate fi identificată personal. Scopul informațiilor este analizarea tendințelor, administrarea site-ului, urmărirea mișcării utilizatorilor pe site și colectarea de informații demografice.</p>

<h2>Cookies și Web Beacons</h2>

<p>Ca orice alt site, imobiliarelaurentiu foloseste 'cookies'. Aceste cookie-uri sunt folosite pentru a stoca informații, inclusiv preferințele vizitatorilor și paginile de pe site-ul web pe care vizitatorul le-a accesat sau le-a vizitat. Informațiile sunt utilizate pentru a optimiza experiența utilizatorilor prin personalizarea conținutului paginii noastre web pe baza tipului de browser al vizitatorilor și/sau a altor informații.</p>

<p>Pentru mai multe informații vă rugăm citiți <a href="https://www.generateprivacypolicy.com/#cookies">Privacy Policy website</a>.</p>



<h2>Politicile de confidențialitate ale partenerilor de publicitate</h2>

<p>Puteți consulta această listă pentru a găsi Politica de confidențialitate pentru fiecare dintre partenerii de publicitate ai imobiliare laurentiu.</p>

<p>Serverele de anunțuri sau rețelele publicitare terță parte utilizează tehnologii precum cookie-uri, JavaScript sau Web Beacon-uri care sunt utilizate în reclamele și link-urile respective care apar pe imobiliarelaurentiu, care sunt trimise direct către browser-ul utilizatorilor. Ei primesc automat adresa dvs. IP atunci când se întâmplă acest lucru. Aceste tehnologii sunt folosite pentru a măsura eficacitatea campaniilor lor de publicitate și/sau pentru a personaliza conținutul publicitar pe care îl vedeți pe site-urile web pe care le vizitați.</p>

<p>Rețineți că imobiliarelaurentiu nu are acces sau control asupra acestor cookie-uri care sunt utilizate de agenții de publicitate terți.</p>

<h2>Politicile de confidențialitate ale terților</h2>

<p>Politica de confidențialitate a imobiliarelaurentiu nu se aplică altor agenți de publicitate sau site-uri web. Astfel, vă sfătuim să consultați Politicile de confidențialitate respective ale acestor servere de anunțuri terțe pentru informații mai detaliate. Poate include practicile și instrucțiunile lor despre cum să renunți la anumite opțiuni.</p>

<p>Puteți alege să dezactivați cookie-urile prin opțiunile individuale ale browserului. Pentru a cunoaște informații mai detaliate despre gestionarea cookie-urilor cu anumite browsere web, acestea pot fi găsite pe site-urile respective ale browserelor.</p>

<h2>CCPA Privacy Rights (Do Not Sell My Personal Information)</h2>

<p>Conform CCPA, printre alte drepturi, consumatorii din California au dreptul la:</p>
<p>Solicitarea ca o companie care colectează date personale ale unui consumator să dezvăluie categoriile și anumite date cu caracter personal pe care o companie le-a colectat despre consumatori.</p>
<p>Solicitarea unei companii să șteargă orice date personale despre consumator pe care le-a colectat o companie.</p>
<p>Solicitarea ca o companie care vinde datele personale ale unui consumator, să nu vândă datele personale ale consumatorului</p>
<p>Dacă faceți o cerere, avem o lună pentru a vă răspunde. Dacă doriți să vă exercitați oricare dintre aceste drepturi, vă rugăm să ne contactați.</p>

<h2>GDPR</h2>

<p>Am dori să ne asigurăm că sunteți pe deplin conștient de toate drepturile dumneavoastră de protecție a datelor. Fiecare utilizator are dreptul la următoarele:</p>
<p>Dreptul de acces – Aveți dreptul de a solicita copii ale datelor dumneavoastră personale. Este posibil să vă percepem o mică taxă pentru acest serviciu.</p>
<p>Dreptul la rectificare – Aveți dreptul de a solicita să corectăm orice informații despre care credeți că sunt inexacte. De asemenea, aveți dreptul de a solicita să completăm informațiile pe care le considerați incomplete.</p>
<p>Dreptul la ștergere – Aveți dreptul de a solicita ștergerea datelor dumneavoastră cu caracter personal, în anumite condiții.</p>
<p>Dreptul de a restricționa prelucrarea – Aveți dreptul de a solicita să restricționăm prelucrarea datelor dumneavoastră cu caracter personal, în anumite condiții.</p>
<p>Dreptul de a vă opune prelucrării – aveți dreptul de a vă opune prelucrării de către noi a datelor dumneavoastră cu caracter personal, în anumite condiții.</p>
<p>Dreptul la portabilitatea datelor – Aveți dreptul de a solicita să transferăm datele pe care le-am colectat către o altă organizație, sau direct către dumneavoastră, în anumite condiții.</p>
<p>Dacă faceți o cerere, avem o lună pentru a vă răspunde. Dacă doriți să vă exercitați oricare dintre aceste drepturi, vă rugăm să ne contactați.</p>
                    </div>
                </section>
                <Footer/>
            </>
        )
    }
}

export default Privacy