import React from 'react'
import { ReactComponent as Logo } from '../images/logo.svg';
import { NavLink } from "react-router-dom";
import '../css/header.css'
import '../css/button.css'

class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            active: false,
            body: false,
        };
    }

    componentDidMount() {
        window.addEventListener('resize', this.onResize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.onResize);
    }

    onResize = () => {
        this.setState({ active: false });
    }

    hamburgerHandler = () => {
        const currentState = this.state.active;
        const body = !this.state.body;
        this.setState({ active: !currentState });
        if(body) {
            document.body.classList.add('is-overflow');
            this.setState({ body: true });
        } else {
            document.body.classList.remove('is-overflow');
            this.setState({ body: false });    
        }
    }

    linkHandler = (event) => {
        document.body.classList.remove('is-overflow');
        this.setState({
            body: false,
            active: false
        });
    }
        
    render() {
        return (
            <>
                <header className="header">
                    <div className="header__container container">
                        <nav className="nav">
                            <div className="nav__logo">
                                <NavLink to="/" className="nav__logo-link">
                                    <Logo />
                                </NavLink>
                            </div>
                            <ul className={this.state.active ? 'nav__list is-active': 'nav__list'}>
                                <li className="nav__item"><NavLink onClick={this.linkHandler} to="/" className="nav__link">Acasă</NavLink></li>
                                <li className="nav__item"><NavLink onClick={this.linkHandler} to="/despre" className="nav__link"><span className="icon --cart"></span>Despre noi</NavLink></li>
                                <li className="nav__item"><NavLink onClick={this.linkHandler} to="/locuinte" className="nav__link">Locuințe</NavLink></li>
                                <li className="nav__item"><a onClick={this.linkHandler} className="nav__button button" href="#contact">Contact</a></li>
                            </ul>
                            <div className="nav__wrapper">
                                <div className={this.state.active ? 'hamburger is-open': 'hamburger'} onClick={this.hamburgerHandler}>
                                    <span className="hamburger__slice"></span>
                                    <span className="hamburger__slice"></span>
                                    <span className="hamburger__slice"></span>
                                </div>
                            </div>
                        </nav>
                    </div>
                </header>
            </>
        )
    }
}

export default Header