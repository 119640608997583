import React from 'react'
import sanityClient from './client.js';

const ContentContext = React.createContext();
export const ContentConsumer = ContentContext.Consumer;

export class ContentProvider extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            homepage: null,
            aboutpage: null,
            contact: null,
            technical: null,
            homeslider: null,
            houseslider: null,
            housepage: null,
        }
    }

    componentDidMount() {
        this.homepageData();
        this.contactData();
        this.homesliderData();
        this.aboutData();
        this.housesData();
        this.technicalData();
        this.houseliderData();
    }

    homepageData = () => {
        sanityClient.fetch(
            `*[_type=="homepage"]{
            heroImage{
                asset->{
                    _id,
                    url
                }
            },
            heroTitle,
            aboutTitle,
            aboutDescription,
            btnAbout,
            columnTitle,
            columnOne,
            columnTwo,
            columnThree,
          }`
          )
          .then((data) => this.setState({
              homepage: data
            }
        )).catch(console.error);
    }

    contactData = () => {
        sanityClient.fetch(
            `*[_type=="contact"]{
                contactTitle,
                contactEmail,
                contactNumber,
                contactFacebook,
                contactFacebookUrl,
                contactAdress,
                contactInsta,
                contactInstaUrl,
          }`
          )
          .then((data) => {
            this.setState({
                contact: data[0]
            })
          }
        ).catch(console.error);
    }

    homesliderData = () => {
        sanityClient.fetch(
            `*[_type=="oferts"]{
                ofertsImage{
                    asset->{
                        _id,
                        url
                    }
                },
                ofertsSubTitle,
                ofertsType,
                ofertsPrice,
                ofertsButton,
                detailsTitle,
                detailsData,
          }`
          )
          .then((data) => {
            this.setState({
                homeslider: data
            })
          }
        ).catch(console.error);
    }

    aboutData = () => {
        sanityClient.fetch(
            `*[_type=="about"]{
                aboutTitle,
                list
          }`
          )
          .then((data) => {
            this.setState({
                aboutpage: data[0]
            })
          }
        ).catch(console.error);
    }

    housesData = () => {
        sanityClient.fetch(
            `*[_type=="houses"]{
                housesTitle,
                housesImage{
                    asset->{
                        _id,
                        url
                    }
                },
                housesNumber,
                housesDescription,
                firstListTitle,
                firstList,
                secondList,
                thirdList
          }`
          )
          .then((data) => {
            this.setState({
                housepage: data[0]
            })
          }
        ).catch(console.error);
    }

    technicalData = () => {
        sanityClient.fetch(
            `*[_type=="technical"]{
                technicalTitle,
                technicalData,
                technicalDraws
          }`
          )
          .then((data) => {
            this.setState({
                technical: data
            })
          }
        ).catch(console.error);
    }

    houseliderData = () => {
        sanityClient.fetch(
            `*[_type=="houseslider"]{
                sliderImage{
                    asset->{
                        _id,
                        url
                    }
                },
          }`
          )
          .then((data) => {
            this.setState({
                houseslider: data
            })
          }
        ).catch(console.error);
    }

    render() {
        const {homepage, aboutpage, contact, homeslider, houseslider, housepage, technical} = this.state;
        return(
            <ContentContext.Provider value={{
                homepage, aboutpage, contact, homeslider, houseslider, housepage, technical
            }}>
                {this.props.children}
            </ContentContext.Provider>
        )
    }
}

export default ContentContext;