import React from 'react'
import Header from '../components/header'
import About from '../components/about'
import Principles from '../components/principles'
import HouseSlider from '../components/house-slider'
import Contact from '../components/contact'
import Footer from '../components/footer'
import '../css/hero.css'
import {ContentConsumer} from '../ContentContext';
import { ReactComponent as Logo } from '../images/logo.svg';

class Home extends React.Component {
    render() {

        return (
            <ContentConsumer>
                {
                    props => {
                        const { homepage } = props;
                        if(homepage != null)
                            return (
                                <>
                                    <Header/>
                                    <section style={{ backgroundImage: `url(${homepage[0]?.heroImage.asset.url})`}} className="hero">
                                        <div className="overlay"></div>
                                        <div className="hero__content container">
                                            <h1 className="hero__title">{homepage[0]?.heroTitle}</h1>
                                        </div>
                                    </section>
                                    <About data={homepage[0]}/>
                                    <Principles data={homepage[0]}/>
                                    <div class="home__house-slider">
                                        <HouseSlider/>
                                    </div>
                                    <hr className="horizontal-line"/>
                                    <Contact/>
                                    <Footer/>
                                </>
                            ) 
                        else
                            return (
                                <>
                                <section className="loading">
                                    <div className="container container--loading">
                                        <div className="ring">
                                            <div className="ring__logo">
                                                <Logo/>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                                </>
                            )
                    }
                }
            </ContentConsumer>
        )
    }
}

export default Home