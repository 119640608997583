import React from 'react'
import '../css/about-house.css'
import HouseSlider from '../components/house-slider'

class AboutHouse extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            description: this.props.data[0],
            number: this.props.data[1],
            list: this.props.data[2],
            title: this.props.data[3],
        }
    }

    render() {
        return (
            <>
                <section className="about-house">
                    <div className="about-house__container container">
                        <div className="about-house__content">
                            <h2 className="about-house__title">{this.state.number}</h2>
                            <span className="about-house__text">locuințe</span>
                            <div className="about-house__wrapper">
                                <p className="about-house__description">
                                    {this.state.description}
                                </p>
                            </div>
                        </div>
                        <HouseSlider/>
                    </div>
                    <div className="technical__info">
                        <div className="container">
                            <h2 className="technical__sub-title">{this.state.title}</h2>
                            <ul className="technical__list">
                                {
                                    this.state.list?.map((item,index) => {
                                        return(
                                            <li key={index} className="technical__item">{item}</li>
                                        )
                                    })
                                }
                            </ul>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}

export default AboutHouse