import React from 'react'
import Header from '../components/header'
import HomeSlider from '../components/home-slider'
import Contact from '../components/contact'
import Footer from '../components/footer'
import {ContentConsumer} from '../ContentContext'
import { ReactComponent as Logo } from '../images/logo.svg';

class AboutPage extends React.Component {
    render() {
        return (
            <ContentConsumer>
                {
                    props => {
                       const { aboutpage } = props;
                       if(aboutpage != null)
                        return( 
                            <>
                                <Header/>
                                <section className="about about--page">
                                    <div className="about__container container">
                                        <div className="about__content">
                                            <h2 className="about__title">{aboutpage?.aboutTitle}</h2>
                                            { aboutpage.list && aboutpage.list?.map((item, index) => {
                                                return (
                                                    <p key={index} className="about__description">
                                                        {item}
                                                    </p>
                                                )
                                            }) }
                                        </div>
                                    </div>
                                </section>
                                <HomeSlider/>
                                <hr className="horizontal-line"/>
                                <Contact/>
                                <Footer/>
                            </>
                        ) 
                       else 
                        return (
                            <>
                            <section className="loading">
                                <div className="container container--loading">
                                    <div className="ring">
                                        <div className="ring__logo">
                                            <Logo/>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            </>
                        )
                    }
                }
            </ContentConsumer>
        )
    }
}

export default AboutPage