import React from 'react'
import { ReactComponent as Logo } from '../images/logo.svg';
import { NavLink, Link } from "react-router-dom";
import '../css/footer.css'

class Footer extends React.Component {
    render() {
        return (
            <>
                <footer className="footer">
                    <div className="footer__container container">
                        <nav className="nav">
                            <div className="nav__logo">
                                <NavLink to="/" className="nav__logo-link">
                                    <Logo />
                                </NavLink>
                            </div>
                            <ul className="nav__list">
                                <li className="nav__item"><NavLink to="/" className="nav__link">Acasă</NavLink></li>
                                <li className="nav__item"><NavLink to="/despre" className="nav__link"><span className="icon --cart"></span>Despre noi</NavLink></li>
                                <li className="nav__item"><NavLink to="/locuinte" className="nav__link">Locuințe</NavLink></li>
                                <li className="nav__item"><Link to="/privacy" className="nav__link">PrivacyPolicy</Link></li>
                            </ul>
                        </nav>
                        <span className="copyright">&copy; Imobiliare Laurențiu 2022</span>
                    </div>
                </footer>
            </>
        )
    }
}

export default Footer