import React from 'react'
import Header from '../components/header'
import AboutHouse from '../components/about-house'
import HomeSlider from '../components/home-slider'
import Technical from '../components/technical'
import Options from '../components/options'
import Contact from '../components/contact'
import Footer from '../components/footer'
import '../css/hero.css'
import {ContentConsumer} from '../ContentContext'
import { ReactComponent as Logo } from '../images/logo.svg';

class Houses extends React.Component {
    render() {
        return (
            <ContentConsumer>
            {
                props => {
                   const { housepage } = props;
                   if(housepage != null)
                    return (
                        <>
                            <Header/>
                            <section style={{ backgroundImage: `url(${housepage?.housesImage.asset.url})`}} className="hero">
                                <div className="overlay"></div>
                                <div className="hero__content container">
                                    <h1 className="hero__title">{housepage?.housesTitle}</h1>
                                </div>
                            </section>
                            <AboutHouse data={[housepage?.housesDescription, housepage?.housesNumber, housepage?.firstList, housepage?.firstListTitle]}/>
                            <Technical data={housepage?.secondList}/>
                            <div className="houses__slider">
                                <HomeSlider/>
                            </div>
                            <Options data={housepage?.thirdList}/>
                            <Contact/>
                            <Footer/>
                        </>
                   ) 
                   else 
                    return (
                        <>
                            <section className="loading">
                                <div className="container container--loading">
                                    <div className="ring">
                                        <div className="ring__logo">
                                            <Logo/>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </>
                    )
                }
            }
            </ContentConsumer>
        )
    }
}

export default Houses