import React from 'react'
import '../css/principles.css'

class Principles extends React.Component {
    constructor(props) {
        super(props);
        this.state = { data: this.props.data };
    }

    render() {
        const data = this.state.data;

        return (
            <>
                <section className="principles">
                    <div className="principles__container container">
                        <div className="principles__content">
                            <h2 className="principles__title">{data.columnTitle}</h2>
                            <div className="principles__columns">
                                <p className="principles__column">
                                    {data.columnOne}
                                </p>
                                <p className="principles__column">
                                    {data.columnTwo}
                                </p>
                                <p className="principles__column">
                                    {data.columnThree}
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}

export default Principles