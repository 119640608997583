import React from 'react'
import Header from '../components/header'
import Footer from '../components/footer'
import '../css/error.css'

class ErrorPage extends React.Component {
    render() {
        return (
            <>
                <Header/>
                <section className="error">
                    <h1 className="error__title">404</h1>
                    <h2 className="error_description">Pagina nu a fost gasită</h2>
                </section>
                <Footer/>
            </>
        )
    }
}

export default ErrorPage