import React, { Component } from "react"
import Slider from "react-slick"
import { NavLink } from "react-router-dom";
import '../css/slick.css'
import {ContentConsumer} from '../ContentContext';

export default class HomeSlider extends Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1
    };
    return (
      <ContentConsumer>
        {
          props => {
            const { homeslider } = props;
            return (
              <div className="slider">
                <div className="container">
                    <h2 className="slider__title">Pachetele noastre</h2>
                    <Slider {...settings}>
                        { homeslider && homeslider?.map((item, index) => {
                          return (
                            <div key={index}>
                              <div className="slick__card">
                                <img className="slick__card-image" src={item?.ofertsImage.asset.url} alt="Locuință"/>
                                <div className="slick__wrapper">
                                    <p className="slick__text">{item?.ofertsSubTitle} <span className="highlight">{item?.ofertsType}</span></p>
                                    <p className="slick__text">{item?.ofertsPrice}</p>
                                </div>
                                <NavLink
                                  to={'/details/' + `${index}`}
                                  className="button"
                                >
                                  {item?.ofertsButton}
                                </NavLink>
                              </div>
                            </div>
                          )
                        }) }
                    </Slider>
                </div>
              </div>
            )
          }
        }
      </ContentConsumer>
    );
  }
}