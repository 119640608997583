import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
import './index.css';
import Home from './pages/home';
import AboutPage from './pages/about-page';
import Houses from './pages/houses';
import Details from './pages/details';
import ErrorPage from './pages/404';
import Privacy from './pages/privacy'
import ScrollToTop from '../src/components/scrollTop';
import {ContentProvider} from './ContentContext';

ReactDOM.render(
  <React.StrictMode>
    <ContentProvider>
      <BrowserRouter>
      <ScrollToTop>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/despre" element={<AboutPage />} />
            <Route path="/locuinte" element={<Houses />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/details/:id" element={<Details />} />
            <Route path='*' element={<ErrorPage />} />
          </Routes>
        </ScrollToTop>
      </BrowserRouter>
    </ContentProvider>
  </React.StrictMode>,
  document.getElementById('root')
);